import React from 'react';
import { Router } from 'react-router';
import { IntlProvider } from 'react-intl-redux'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import configureStore, { history } from './store';
import './App.less';

import Root from './containers/Root';

// Setup icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt, faExclamationCircle, faCheck, faSpinner, faRecycle } from '@fortawesome/free-solid-svg-icons';

library.add(faTrashAlt, faExclamationCircle, faCheck, faSpinner, faRecycle);

export const store = configureStore();

const App = () =>
  <Provider store={store}>
    <IntlProvider>
      <Router history={history}>
        <div className="App">
          <Switch>
            <Route path="/" component={Root}/>
          </Switch>
        </div>
      </Router>
    </IntlProvider>
  </Provider>;

export default App;
