import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { saveState, loadState } from 'store/localForage';
import Loading from 'containers/Loading';
import Routes from 'containers/Routes';
import { hydrateStore } from 'actions/store';

const onResize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const Root = ({ scans, hydrateStore }) => {
  const [loading, setLoading] = useState(true);

  // On resize
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  // Save state upon auth/org change
  useEffect(() => {
    saveState({ scans });
  }, [scans]);

  // Load state when loading the app
  useEffect(() => {
    loadState()
      .then(hydrateStore)
      .then(() => setLoading(false));
  }, [ hydrateStore ]);


  return (loading ? <Loading /> : <Routes />);
}

const mapStateToProps = ({ scans }) => ({
  scans,
});

export default connect(mapStateToProps, { hydrateStore })(Root);
