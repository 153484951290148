import localForage from 'localforage';

const storage = localForage.createInstance({
  name: 'aplanet-qr-front',
  version: 1.0,
  storename: 'keyvaluepairs',
  description: 'aplanet QR Scanner frontend cache'
});

const ITEM_NAME = 'saved_state';

export const loadState = () => storage.getItem(ITEM_NAME).catch(err => undefined);
export const saveState = (state) => storage.setItem(ITEM_NAME, state).catch(err => undefined);

