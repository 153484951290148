import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import QrReader from 'react-qr-reader';

import {
  reportQrScan,
  clearQrScans,
  resendQrScan,
} from '../../actions/api';

import './style.less';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavBar, List, Button, WhiteSpace, WingBlank } from 'antd-mobile';

const Scanner = ({ intl, scans, reportQrScan, clearQrScans, resendQrScan }) => {
  const t = intl.messages;

  const {
    items,
  } = scans;


  const handleScan = useCallback((scan) => {
    if(!scan) return; // Nothing was scanner in this period, move along

    reportQrScan(scan);
  }, [ reportQrScan ]);

  const getRightSideFromStatus = useCallback((status, extra) => {
    if(status === 'failed') return (
      <Button
        icon={<FontAwesomeIcon icon="recycle" small />}
        onClick={() => resendQrScan(extra)}
        size="small"
      >
        { t.resubmit }
      </Button>
    );
    if(status === 'sending') return <FontAwesomeIcon icon="spinner" spin />;
    if(status === 'scanned') return <FontAwesomeIcon color='green' icon="check" />;
    if(status === 'invalid') return <FontAwesomeIcon color='red' icon="exclamation-circle" />;
  }, [ t, resendQrScan ]);

  const getThumbFromStatus = useCallback((status, extra) => {
    if(status === 'scanned') return (extra || {}).avatar;
    return undefined
  }, []);

  const getLabelFromStatus = useCallback((status, extra) => {
    if(status === 'sending') return t.sending;
    if(status === 'scanned') return (extra || {}).name || t.no_name;
    if(status === 'invalid') return t.invalid;
    if(status === 'failed') return t.failed;
    return extra;
  }, [ t ]);


  return (
    <React.Fragment>
      <NavBar
        mode="light"
        leftContent={
          <div className="Scanner-logo">
            <img src="/images/aplanet.png" alt="Aplanet" />
          </div>
        }
      ></NavBar>
      <WhiteSpace size="md"/>
      <WingBlank size="xl">
        <QrReader
          delay={500}
          onError={(err) => { console.error(err) }}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      </WingBlank>
      <WhiteSpace size="md"/>
      <List
        renderHeader={
          <Button
            icon={<FontAwesomeIcon icon="trash-alt" />}
            onClick={clearQrScans}
          >{ t.clear }</Button>
        }
      >
        {items.map(({ timestamp, extra, status }) => (
          <List.Item
            key={timestamp}
            extra={getRightSideFromStatus(status)}
            thumb={getThumbFromStatus(status, extra)}
          >
            { getLabelFromStatus(status, extra) }
          </List.Item>
        ))}
      </List>
    </React.Fragment>
    )
}

const mapStateToProps = ({
  scans,
}) => ({
  scans,
});

export default injectIntl(connect(mapStateToProps, { reportQrScan, clearQrScans, resendQrScan })(Scanner));
