import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import './style.less';

const Loading = () => {
  return (
    <div className="Loading-back">
      <img src="/images/favicon-512x512.png" alt="aplanet" />
    </div>
  )
}

const mapStateToProps = () => ({});

export default injectIntl(connect(mapStateToProps)(Loading));
