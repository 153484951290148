import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router';
import Scanner from 'containers/Scanner';

const Routes = ({ auth }) => {
  return (
    <Switch>
      <Route component={Scanner} />
    </Switch>
    )
}

const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps)(Routes));
