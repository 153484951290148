const parseResponse = (response) => {
  if(!response) {
    return Promise.reject({ code: 400, text: 'Bad Request', response });
  }
  if(!response.ok) {
    return Promise.reject({ code: response.status, text: response.statusText, response });
  }
  return response.json().catch(() => ({}));
};

export {
  parseResponse,
}
