import intl from 'intl';
import { addLocaleData } from 'react-intl';
import { updateIntl } from 'react-intl-redux';

// Locale Data
import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import locale_pt from 'react-intl/locale-data/pt';

// Date-fns locale
import dateFns_en from 'date-fns/locale/en';
import dateFns_es from 'date-fns/locale/es';
import dateFns_pt from 'date-fns/locale/pt';


// This is a polyfill
import 'intl/locale-data/jsonp/en.js';
import 'intl/locale-data/jsonp/es.js';
import 'intl/locale-data/jsonp/pt.js';

// Messages
import messages_es_ES from './es-ES.json';
import messages_es_MX from './es-MX.json';
import messages_en_US from './en-US.json';
import messages_pt_BR from './pt-BR.json';
import messages_pt_PT from './pt-PT.json';

const locales = [
  ...locale_en,
  ...locale_es,
  ...locale_pt,
];

const en_US = {
  locale: 'en-US',
  messages: messages_en_US,
  formats: {
    dateFns: dateFns_en,
  },
};

const es_ES = {
  locale: 'es-ES',
  messages: messages_es_ES,
  formats: {
    dateFns: dateFns_es,
  },
};

// eslint-disable-next-line no-unused-vars
const es_MX = {
  locale: 'es-MX',
  messages: messages_es_MX,
  formats: {
    dateFns: dateFns_es,
  },
};

// eslint-disable-next-line no-unused-vars
const pt_BR = {
  locale: 'pt-BR',
  messages: messages_pt_BR,
  formats: {
    dateFns: dateFns_pt,
  },
};

const pt_PT = {
  locale: 'pt-PT',
  messages: messages_pt_PT,
  formats: {
    dateFns: dateFns_pt,
  },
};

const config = {
  'en': {
    default: en_US,
    'en-US': en_US,
  },
  'es': {
    default: es_ES,
    'es-ES': es_ES,
    //'es-MX': es_MX,
  },
  'pt': {
    default: pt_PT,
    'pt-BR': pt_BR,
    'pt-PT': pt_PT,
  },
  default: {
    default: en_US,
  },
};

const defaultLocale = 'en-US';

const getConfig = (locale = defaultLocale) => {
  const langConfig = config[locale.slice(0, 2)] || config.default;
  return langConfig[locale] || langConfig.default;
};

const setupIntl = () => {
  if (typeof window.Intl === 'undefined') window.Intl = intl;
  addLocaleData(locales);

  return getConfig(navigator.language || navigator.userLanguage);
};

const changeLocale = (locale) => updateIntl(getConfig(locale));

export {
  setupIntl,
  changeLocale,
};

