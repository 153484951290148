import { loop, Cmd } from 'redux-loop';

import { parseResponse } from 'utils';
import config from 'config';

import scannedAudio from 'misc/sounds/scanned.mp3';
import invalidAudio from 'misc/sounds/invalid.mp3';
import failedAudio from 'misc/sounds/failed.mp3';

const MIN_TIME_BETWEEN_SCANS = 5000; // 5 seconds
const VALID_QR_REGEXP = new RegExp(`^${config.API_URL}/p/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`);
const SOUNDS = {
  scanned: new Audio(scannedAudio),
  invalid: new Audio(invalidAudio),
  failed: new Audio(failedAudio),
};
const VIBRATION = {
  scanned: [500],
  invalid: [500, 30, 100, 30, 500],
  failed: [300, 30, 300],
};

const qrBeep = (type) => {
  SOUNDS[type].play();
  navigator.vibrate(VIBRATION[type]);
};

const requestQrSubmit = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
    })

    const result = await parseResponse(response);

    return {
      url,
      result,
    };
  } catch(err) {
    // eslint-disable-next-line no-throw-literal
    throw { ...err, request: { url } };
  }
};

const qrSubmitRequest = (url) => ({
  type: 'QR_SUBMIT_REQUEST',
  url,
});

const qrSubmitSuccess = (response) => ({
  type: 'QR_SUBMIT_COMPLETE',
  response,
});

const qrSubmitFail = ({ code, text, request }) => ({
  type: 'QR_SUBMIT_FAIL',
  code,
  text,
  request,
});

const checkValidQr = (url) => {
  const matches = VALID_QR_REGEXP.exec(url);
  return [!!matches, matches ? matches[1] : 'invalid'];
};

const initialState = {
  items: [], // Format is { timestamp, url, status, extra }
  loading: 0,
  error: false,
};

const reducer = (state = initialState, action) => {
  let index;
  switch(action.type) {
    case 'REPORT_QR_SCAN':
      if(!action.url) return state;

      index = state.items.findIndex(({url}) => url === action.url);
      if(index >= 0 && (action.timestamp - state.items[index].timestamp <= MIN_TIME_BETWEEN_SCANS)) {
        // Repeated scan, updating timestamp
        return {
          ...state,
          items: [
            ...state.items.slice(0, index),
            { ...state.items[index], timestamp: action.timestamp },
            ...state.items.slice(index+1)
          ],
        }
      }

      const [validQr, token] = checkValidQr(action.url);
      return loop({
        ...state,
        items: [
          {
            url: action.url,
            timestamp: action.timestamp,
            status: (validQr ? 'sending' : 'invalid'),
            extra: token,
          },
          ...state.items
        ],
      },
      (
        validQr ?
          Cmd.action(qrSubmitRequest(action.url)) :
          Cmd.run(qrBeep, { args: [ 'invalid' ] })
      ));

    case 'QR_SUBMIT_REQUEST':
      return loop({
        ...state,
        loading: state.loading + 1,
      }, Cmd.run(requestQrSubmit, {
        args: [ action.url ],
        successActionCreator: qrSubmitSuccess,
        failActionCreator: qrSubmitFail
      }));
    case 'QR_SUBMIT_COMPLETE':
      index = state.items.findIndex(({url, status}) => url === action.response.url && status === 'sending');

      if(index < 0) {
        return state;
      }

      return loop({
        ...state,
        loading: state.loading - 1,
        items: [
          ...state.items.slice(0, index),
          { ...state.items[index], status: 'scanned', extra: action.response.result },
          ...state.items.slice(index+1)
        ],
      }, Cmd.run(qrBeep, { args: [ 'scanned' ] }));
    case 'QR_SUBMIT_FAIL':
      index = state.items.findIndex(({url, status}) => url === action.request.url && status === 'sending');

      if(index < 0) {
        return state;
      }

      return loop({
        ...state,
        loading: state.loading - 1,
        items: [
          ...state.items.slice(0, index),
          { ...state.items[index], status: 'failed', extra: action.request.url },
          ...state.items.slice(index+1)
        ],
      }, Cmd.run(qrBeep, { args: [ 'failed' ] }));
    case 'CLEAR_QR_SCANS':
      return initialState;
    case 'HYDRATE_STORE':
      return action.state ? { ...state, ...action.state.scans, loading: false, error: null } : state;
    default:
      return state;
  }
};

export {
  reducer as scans,
};
