const reportQrScan = (url) => ({
  type: 'REPORT_QR_SCAN',
  timestamp: Date.now(),
  url,
});

const clearQrScans = () => ({
  type: 'CLEAR_QR_SCANS',
});

const resendQrScan = (url) => ({
  type: 'QR_SUBMIT_REQUEST',
  url,
});


export {
  reportQrScan,
  clearQrScans,
  resendQrScan,
}
